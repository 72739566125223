<template>
  <div class="cart">
    <div class="goods-list" v-if="goodsList != null && goodsList.length > 0">
      <van-checkbox-group v-model="checked" ref="checkboxGroup" @change="listenCheckboxGroup">
        <div class="goods-item" v-for="(item, index) in goodsList" :key="index" >
          <van-checkbox :name="index" icon-size="24px" checked-color="#ee0a24" />
          <van-swipe-cell>
            <van-card
              :num="item.amount"
              :desc="item.name"
              class="goods-card"
              :thumb="item.orderImg"
            >
              <template #price>
                <span v-if="vip==1">￥{{item.vipPrice}}</span>
                <span v-else>￥{{item.marketPrice}}</span>
              </template>
              <template #num>
                <van-stepper  v-model="goodsList[index].amount" button-size="22" disable-input integer min="1" max="99" @change="listenQuantity" />
              </template>
              <template #footer>
                <van-button square text="删除" type="danger" class="delete-button" @click="deleteGoods(index)" />
              </template>
            </van-card>

          </van-swipe-cell>
        </div>

      </van-checkbox-group>
    </div>
    <div v-else class="empty">

    </div>
    <van-submit-bar :price="totalPrice*100" button-text="去结算" @submit="onSubmit">
      <van-checkbox v-model="checkAll" checked-color="#ee0a24" @click="toggleAll">全选</van-checkbox>
      <!-- <template #tip>
        你的收货地址不支持配送, <span @click="onClickLink">修改地址</span>
      </template> -->
    </van-submit-bar>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { showToast } from 'vant';
import { shopCarList,deleteCar} from '@/service/order';
export default {
  setup() {
    const amount=ref(1);
    const router = useRouter();
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    const goodsList = ref([]);  // 购物车商品列表
    const totalPrice = ref(0);
    const checkAll = ref(false);
    const checked = ref([]);
    const checkboxGroup = ref(null);
    const vip=ref(0);
    const toggleAll = () => {
      checkboxGroup.value.toggleAll(checkAll.value);
    }
    // 读取购物车商品
    const loadData = async () => {
      let res = await shopCarList(token.userId);
      goodsList.value = res.data.data.list;
      vip.value=res.data.data.vip;
    }
    const deleteGoods = (index) => {
      //操作数据库
      deleteCar(goodsList.value[index].id).then(res=>{
        if(res.data.status==1){
          showToast("删除成功")
          loadData();
        }

      })
    }
    // 监听选中商品，修改总计
    const listenCheckboxGroup = () => {
      let list=checked.value;
      let total = 0;
      if(list.length < goodsList.value.length && checkAll.value) {
        checkAll.value = false;
      } else if(list.length === goodsList.value.length && !checkAll.value) {
        checkAll.value = true;
      }
        for(let idx of list) {
          if(vip.value==1){
            total += goodsList.value[idx].vipPrice * goodsList.value[idx].amount;
          }else{
            total += goodsList.value[idx].marketPrice * goodsList.value[idx].amount;
          }

        }
      totalPrice.value = total;

    }
    // 监听商品数量，修改总计
    const listenQuantity = (value) => {
      let total = 0;
      console.log("选中：",checked.value)
      for(let idx of checked.value) {
        if(vip.value==1){
          total += goodsList.value[idx].vipPrice * value;
        }else{
          total += goodsList.value[idx].marketPrice * value;
        }

        goodsList.value[idx].amount=value;
      }
      totalPrice.value = total;
    }
    const onSubmit = () => {
      console.log("结算时选中商品集合")
      console.log("购物车结合：",goodsList.value)
      if(totalPrice.value==0){
        showToast("请选择购物车商品")
      }else{
        let products=[];
         for(let idx of checked.value){
            let product={};
            product.packId=goodsList.value[idx].goodsId;
            product.amount=goodsList.value[idx].amount;
            products.push(product);

         }
        router.push({path: '/pay/book', query: {goods:JSON.stringify(products)}});
      }


    }

    onMounted(() => {
      loadData();
    })

    return {
      goodsList,
      totalPrice,
      checkAll,
      checked,
      checkboxGroup,
      toggleAll,
      onSubmit,
      deleteGoods,
      listenCheckboxGroup,
      listenQuantity,
      vip,
      amount
    }
  }
}
</script>

<style lang="scss">
  .cart {
    .goods-list {
      padding: 16px 16px;
      .goods-item {
        display: flex;
        background-color: #fff;
        padding: 8px 16px;
        border-radius: 16px;
        margin-bottom: 16px;
        .van-checkbox {
          padding: 0 8px;
        }
        .van-swipe-cell {
          flex: 1;
          .goods-card {
            padding-left: 16px;
            .van-card__price {
              color: red;
            }
          }

        }
      }
    }
  }
  .goods-card {
    margin: 0;
    background-color: #fff;
  }

  .delete-button {
    height: 100%;
  }
</style>
